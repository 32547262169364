/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  forwardRef,
  type ButtonHTMLAttributes,
  type DetailedHTMLProps,
} from 'react'
import { twMerge } from 'tailwind-merge'

type ButtonVariants = 'outlined' | 'contained' | 'text' | 'reversed'
type ButtonColors =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'dark'
  | 'error'
  | 'success'
  | 'warning'
  | 'info'

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ButtonVariants
  color?: ButtonColors
  size?: 'small' | 'medium' | 'large' | 'extraLarge'
}

export const LightPrimaryButton = forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, 'color' | 'variant'>
>(({ className, size = 'medium', children, ...props }, ref) => {
  const variant = 'contained' as ButtonVariants
  const color = 'primary' as ButtonColors
  const mergedClassName = twMerge(
    `disabled:text-white bg-primary text-primary-contrast-text hover:bg-primary-dark focus:bg-primary-light disabled:bg-gray-300`,
    className,
  )

  return (
    <Button
      // @ts-ignore don't know why it doesn't like it
      ref={ref}
      className={mergedClassName}
      size={size}
      variant={variant}
      color={color}
      {...props}
    >
      {children}
    </Button>
  )
})

export const LightSecondaryButton = forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, 'color' | 'variant'>
>(({ className, size = 'medium', children, ...props }, ref) => {
  const variant = 'text' as ButtonVariants
  const color = 'primary' as ButtonColors

  const mergedClassName = twMerge(
    `bg-secondary-dark text-white hover:bg-secondary focus:bg-secondary-light disabled:bg-disabled-opacity disabled:text-white`,
    className,
  )

  return (
    <Button
      // @ts-ignore don't know why it doesn't like it
      ref={ref}
      className={mergedClassName}
      size={size}
      variant={variant}
      color={color}
      {...props}
    >
      {children}
    </Button>
  )
})

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      size = 'medium',
      children,
      variant = 'text',
      color = 'primary',
      ...props
    },
    ref,
  ) => {
    const sizeClass = {
      small: 'h-8 min-w-[64px] text-xs font-medium rounded-2xl px-1',
      medium: 'h-10 min-w-[80px] text-sm font-medium rounded-[20px] px-2',
      large: 'h-12 min-w-[96px] text-md font-medium rounded-3xl px-3',
      extraLarge: 'h-14 min-w-[112px] text-lg font-medium rounded-[28px] px-4',
    }[size]

    const variantClass = {
      outlined: 'border-solid border-2',
      contained: 'border-solid border-2',
      text: '',
      reversed: '',
    }[variant]

    const colours = {
      primary: {
        outlined:
          'border-dark text-dark hover:text-white hover:bg-dark-dark focus:bg-dark-light disabled:bg-transparent disabled:bg-gray-300 disabled:border-disabled',
        contained:
          'bg-dark text-primary-contrast-text hover:bg-dark-dark focus:bg-dark-dark disabled:bg-grey/80',
        text: 'text-dark disabled:text-disabled hover:bg-primary/10 hover:no-underline underline',
        reversed: 'text-dark bg-white',
      },
      secondary: {
        outlined:
          'border-secondary text-dark hover:bg-secondary-light focus:bg-secondary-light disabled:bg-transparent disabled:bg-gray-300 disabled:border-disabled ',
        contained: 'bg-secondary text-secondary-contrast-text',
        text: 'text-secondary disabled:bg-transparent disabled:bg-gray-300',
        reversed: 'bg-secondary-contrast-text text-secondary',
      },
      accent: {
        outlined:
          'border-secondary text-dark hover:bg-secondary-light focus:bg-secondary-light disabled:bg-transparent disabled:bg-gray-300 disabled:border-disabled ',
        contained:
          'bg-accent hover:bg-accent-bg focus:bg-accent-bg text-secondary-contrast-text text-white',
        text: 'text-secondary disabled:bg-transparent disabled:bg-gray-300',
        reversed: 'bg-secondary-contrast-text text-secondary',
      },
      dark: {
        outlined: 'border-dark text-dark',
        contained: 'bg-dark text-dark-contrast-text',
        text: 'text-dark',
        reversed: 'bg-dark-contrast-text text-dark',
      },
      error: {
        outlined: 'border-error text-error',
        contained: 'bg-error text-error-contrast-text',
        text: 'text-error',
        reversed: 'bg-error-contrast-text text-error',
      },
      success: {
        outlined: 'border-success text-success',
        contained: 'bg-success text-success-contrast-text',
        text: 'text-success',
        reversed: 'bg-success-contrast-text text-success',
      },
      warning: {
        outlined: 'border-warning text-warning',
        contained: 'bg-warning text-warning-contrast-text',
        text: 'text-warning',
        reversed: 'bg-warning-contrast-text text-warning',
      },
      info: {
        outlined: 'border-info text-info',
        contained: 'bg-info text-info-contrast-text',
        text: 'text-info',
        reversed: 'bg-info-contrast-text text-info',
      },
    }[color][variant]

    const mergedClassName = twMerge(
      'relative ml-2 inline-flex min-w-[64px] items-center justify-center font-medium no-underline',
      sizeClass,
      colours,
      variantClass,
      className,
    )

    return (
      <button ref={ref} className={mergedClassName} {...props}>
        {children}
      </button>
    )
  },
)
