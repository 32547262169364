/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
export const slugify = (text: string | undefined | null) => {
  if (!text) {
    return ''
  }
  const extension =
    /(\.js$|\.js.map$|\.txt$|\.png$|\.ico$|\.pdf$|\.json$|\.html$)/g
  const suffix = text.match(extension)

  const suffixString = suffix ? suffix[0] : ''

  return (
    text
      .replaceAll(extension, '')
      .normalize('NFD')
      .replaceAll(/[^\s\w/-]+/g, '')
      .replaceAll(/\s+/g, '-')
      .replaceAll(/-{2,}/g, '-')
      .replaceAll(/[./]/g, '')
      .toLowerCase()
      .trim()
      // eslint-disable-next-line unicorn/prefer-spread
      .concat(suffixString)
  )
}

export const unslugify = (text: string) => {
  if (!text) {
    return ''
  }
  return decodeURI(text.replaceAll('-', ' '))
}

export const toRiskifiedSessionId = (
  userId: string | null | undefined,
  cartId: string | null | undefined,
): string => `${userId || 'guest'}-${cartId || ''}`
