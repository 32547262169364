const Regex =
  /(https?:\/\/(?:www\.|(?!www))[\dA-Za-z][\dA-Za-z-]+[\dA-Za-z]\.\S{2,}|www\.[\dA-Za-z][\dA-Za-z-]+[\dA-Za-z]\.\S{2,}|https?:\/\/(?:www\.|(?!www))[\dA-Za-z]+\.\S{2,}|www\.[\dA-Za-z]+\.\S{2,})/

export const isValidHttpUrl = (string?: string | null) => {
  if (string == null) {
    return false
  }
  return Regex.test(string)
}

export const hostURL = (): string =>
  typeof window === 'undefined'
    ? (process.env.NEXT_PUBLIC_HOST ??
      (process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL
        ? `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`
        : 'http://localhost:3000'))
    : `${window?.location?.protocol}//${window?.location?.hostname}${
        window?.location?.port ? ':' + window?.location?.port : ''
      }`

export const localAssetUrl = (imagePath: string): string =>
  `${process.env.NEXT_PUBLIC_LOCAL_ASSET_HOST ?? ''}${imagePath}`

const isAbsoluteUrl = (url?: string | null) =>
  !!url?.toLowerCase().startsWith('http')

export const getAbsoluteUrl = (url: string) => {
  const baseUrl = hostURL()
  const newUrl = isAbsoluteUrl(url) ? url : `${baseUrl}${url}`
  return isAbsoluteUrl(newUrl)
    ? newUrl
    : `${
        baseUrl?.includes('localhost') ? 'http://' : 'https://'
      }${baseUrl}${url}`
}
export const getRelativeUrl = (url: string) =>
  isAbsoluteUrl(url) ? new URL(url).pathname : url

export const paginateCanonicalUrl = (
  path: string,
  page: string | undefined,
  searchParams?: Record<string, string>,
) => {
  const params = new URLSearchParams(searchParams)

  if (page && page !== '1') {
    params.set('page', page)
  }

  const query = params.toString()

  return path + (query ? `?${query}` : '')
}

export const cleanSearchTerm = (searchTerm?: string) => {
  try {
    return decodeURIComponent(searchTerm?.replaceAll('%%', '%') ?? '')
  } catch {
    // if the searchTerm is invalid, we just completely drop it
    // this will only happen when the user manually entered the search
    // string in the url
    return ''
  }
}
